import React from "react";
import Layout from "../components/layout";

const Press = () => {
  return (
    <Layout>
      <div className="relative py-16 bg-white overflow-hidden">
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1 className="block  text-center text-customBlue font-semibold tracking-wide uppercase">
              Press
            </h1>
      
            <p>

                lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dollars sit amet facilisis magna etiam.
                <br />
                lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dollars sit amet facilisis magna etiam.
                lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dollars sit amet facilisis magna etiam.
                loewm ipsum dolor sit amet, consectetur adipiscing elit. Sed dollars sit amet facilisis magna etiam.
                loewm ipsum dolor sit amet, consectetur adipiscing elit. Sed dollars sit amet facilisis magna etiam.
                loewm ipsum dolor sit amet, consectetur adipiscing elit. Sed dollars sit amet facilisis magna etiam.
                loewm ipsum dolor sit amet, consectetur adipiscing elit. Sed dollars sit amet facilisis magna etiam.
                loewm ipsum dolor sit amet, consectetur adipiscing elit. Sed dollars sit amet facilisis magna etiam.
                lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dollars sit amet facilisis magna etiam.
                lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dollars sit amet facilisis magna etiam.
                loewm ipsum dolor sit amet, consectetur adipiscing elit. Sed dollars sit amet facilisis magna etiam.
                loewm ipsum dolor sit amet, consectetur adipiscing elit. Sed dollars sit amet facilisis magna etiam.
                loewm ipsum dolor sit amet, consectetur adipiscing elit. Sed dollars sit amet facilisis magna etiam.
                loewm ipsum dolor sit amet, consectetur adipiscing elit. Sed dollars sit amet facilisis magna etiam.
                loewm ipsum dolor sit amet, consectetur adipiscing elit. Sed dollars sit amet facilisis magna etiam.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Press;
